<template>
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="card p-15 h-100">
        <div class="d-flex align-items-center">
          <div>
            <img
              style="width: 150px"
              class="rounded-circle"
              src="media/avatars/blank.png"
              alt=""
            />
          </div>
          <div class="mx-5">
            <h2 class="text-gray">{{ model.nombre }}</h2>
            <h4 class="text-blue">Invitado</h4>
          </div>
        </div>
        <div class="col-12 my-5 p-5">
          <ul class="listado-info" style="list-style: none">
            <li class="my-5">
              <label class="text-blue" for="">Nombre</label>
              <input
                type="text"
                class="form-control rounded-0 bg-gray-input border-0"
                v-model="model.nombre"
              />
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Email</label>
              <input
                type="text"
                class="form-control rounded-0 bg-gray-input border-0"
                v-model="model.correo"
              />
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Número de contacto</label>
              <div class="d-flex justify-content-between align-items-center">
                <input
                  type="text"
                  class="form-control rounded-0 bg-gray-input border-0"
                  v-model="model.movil"
                />
              </div>
            </li>
            <li class="my-5">
              <label class="text-blue" for="">Asignar contraseña</label>
              <div class="d-flex justify-content-between align-items-center">
                <input
                  type="password"
                  class="form-control rounded-0 bg-gray-input border-0"
                  v-model="model.clave"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12" style="height: 80vh">
      <div class="card p-1 h-75">
        <div class="p-5 border-bottom d-flex">
          <h4 class="text-blue me-2 w-50">Asignar grupos</h4>
          <div class="form-control d-flex align-items-center p-0 w-50">
            <i class="bi bi-search mx-2"></i>
            <input
              class="border-0 w-50"
              type="text"
              placeholder="Buscar"
              name=""
              id=""
            />
          </div>
        </div>
        <div
          class="card-body p-2"
          style="height: calc(100% - 60px); overflow-y: auto"
        >
          <div v-for="(grupo, j) in grupos" :key="j" id="grupos">
            <div class="d-flex my-2 justify-content-between me-2">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px me-3">
                  <div class="symbol-label bg-light rounded-circle">
                    <img
                      src="media/svg/brand-logos/blank.png"
                      alt=""
                      class="h-50"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href="#"
                    class="fs-6 text-gray-800 text-hover-blue fw-bolder"
                    >{{ grupo.nombre }}</a
                  >
                </div>
              </div>
              <div class="d-flex align-items-center">
                <input
                  class="form-check-input"
                  :value="grupo._id"
                  type="checkbox"
                  :v-model="grupoId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block my-4 h-25">
        <button class="btn bg-blue text-white rounded-pill w-100" type="submit">
          <!-- <span v-if="isLoading">Procesando...</span> -->
          <span>Añadir invitado</span>
        </button>
        <button type="button" class="btn btn-light rounded-pill w-100">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import miGrupoService from "@/services/miGrupo.service";
import { useRoute } from "vue-router";
import { ErrorMessage, useField, useForm } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "editar-invitado-editar",
  components: {},
  setup() {
    const route = useRoute();
    const grupoId = ref([]);
    const traerInformacionDelUsuario = () => {
      miGrupoService.verDelUsuario(route.params.id).then((data) => {
        console.log("info usuario", data.data);
        model.nombre = data.data.nombre;
        model.correo = data.data.correo;
        model.movil = data.data.movil;
        model.clave = data.data.clave;
      });
    };
    const grupos = ref([]);
    const traerGrupos = () => {
      miGrupoService.listarGrupos().then((res) => {
        console.log(res);
        grupos.value = res.data.data;
      });
    };
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required("Este campo es requerido"),
      correo: Yup.string()
        .email("Ingrese un correo valido")
        .required("Este campo es requerido"),
      movil: Yup.string().required("Este campo es requerido"),
      clave: Yup.string().required("Este campo es requerido"),
    });
    const { handleSubmit, values: model } = useForm({
      validationSchema,
    });
    useField("nombre", null, { initialValue: "" });
    useField("correo", null, { initialValue: "" });
    useField("movil", null, { initialValue: "" });
    useField("clave", null, { initialValue: "" });
    useField("rol", null, { initialValue: 2 });
    const editarInfoUsuario = handleSubmit((values) => {
      values.gruposIds = grupoId.value.join(",");
      miGrupoService.editarUsuarios(route.params.id, values).then((result) => {
        console.log(result);
      });
    });
    onMounted(() => {
      traerInformacionDelUsuario(route.params.id);
      editarInfoUsuario();
      traerGrupos();
    });
    return { grupos, grupoId, model };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.listado-info {
  padding: 0;
  list-style: none;
}
.listado-info li label {
  font-size: 1.125rem;
}
.listado-info li p {
  font-size: 1.125rem;
  color: #999999;
}
</style>
