import axios from "axios";
import config from "../config/suscripcion.config";
const invitadoAgrear = (paylaod) =>
  axios.post(`${config.host}/usuarios`, paylaod);
const listarGrupos = () => axios.get(`${config.host}/grupos?page=1&limit=25`);
const crearGrupos = (paylaod) => axios.post(`${config.host}/grupos`, paylaod);
const listarUsuariosPorGrupo = (id) =>
  axios.get(`${config.host}/grupos/${id}/usuarios`);
const editarUsuarios = (id, payload) =>
  axios.put(`${config.host}/usuarios/${id}`, payload);

const listarUsuarios = (queryParams = {}) =>
  axios.get(`${config.host}/usuarios`, { params: { ...queryParams } });

const verDelUsuario = (id) => axios.get(`${config.host}/usuarios/${id}`);
const eliminarUsuarios = (id) => axios.delete(`${config.host}/usuarios/${id}`);
const eliminarGrupo = (id) => axios.delete(`${config.host}/grupos/${id}`);

export default {
  invitadoAgrear,
  listarGrupos,
  crearGrupos,
  listarUsuariosPorGrupo,
  editarUsuarios,
  listarUsuarios,
  verDelUsuario,
  eliminarUsuarios,
  eliminarGrupo,
};
